.ContactUs {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-input {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.submitButton {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.message {
  padding: 10px;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 60px;
      background-color: #d8d1d167;
      color: #fff;
      text-align: center;
      border-top: 1px solid #ddd;
      padding-top: 1px;
}