.admin-panel {
    background-color: white;
}

.admin-div-main {
    background-color: rgb(195, 201, 201);
    position: absolute;
    width: 934px;
    height: 937px;
    left: 376px;
    top: 85px;
}