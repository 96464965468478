* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 600px) {
  body {
    font-size: 14px;
  }
}
.background-1 {
  height: 100%;
  height: 100%;

  background-position: center;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

html {
  height: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.logo-1 {
  width: 100px;
  margin: 5px;
}

.serviceimg {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  border-radius: 10%;
  align-items: center;
}

.admin_serviceimg {
  width: 30%;
  height: 30%;
  margin-bottom: 20px;
  border-radius: 10%;
  align-items: center;
}

.cartimg {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  border-radius: 10%;
  align-items: center;
}

.name-gallery {
  color: #128391;
  font-size: 30px;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 767px) {
  .name-gallery {
    font-size: 24px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .name-gallery {
    font-size: 27px;
  }
}

@media screen and (min-width: 992px) {
  .name-gallery {
    font-size: 30px;
  }
}


.serviceimg-gallery {
  width: 250px;
  height: 250px;
  border-radius: 10%;
  align-items: center;
}

.App {
  text-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  margin-left: 50px;
  margin-right: 100px;
}

.Test {
  width: 70%;
  margin: 0 auto;
}

.navbar {
  height: 60px;
  width: 100%;
  margin-bottom: 2%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: transparent;
}

.container {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  flex-wrap: wrap;
}

.container2 {
  width: 99%;
  margin: auto;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  flex-wrap: wrap;
}

.container-gallery {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  flex-wrap: wrap;
}

.container-gallery .card_inner {
  background-color: #f2f4f6;
  border-radius: 10px;
  min-width: 500px;
  max-width: 550px;
  max-height: 370px;
  width: 100%;
  box-shadow: 5px 5px 10px 2px rgba(95, 95, 95, 0.24);

  margin: 0 auto;
}

.card_item {
  padding: 15px 30px;
  text-align: center;
}

.container .card_item:hover {
  transform: scale(1.02);
  transition: transform 0.3s;
  cursor: pointer;
}

.container .card_inner {
  background-color: #f2f4f6;
  border-radius: 10px;
  padding: 20px;
  max-width: 550px;
  min-height: 370px;
  max-height: 1000px;
  width: 100%;
  box-shadow: 5px 5px 10px 2px rgba(95, 95, 95, 0.24);
}

.container-gallery .card_inner {
  background-color: #f2f4f6;
  border-radius: 10px;
  padding: 15px 10px;
  width: 90%;
  box-shadow: 5px 5px 10px 2px rgba(95, 95, 95, 0.24);
}

.container .name {
  color: #128391;
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  max-width: 200px;
  max-height: 100px;
  word-wrap: break-word;
  margin-top: 5px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.container2 .name {
  color: #128391;
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  max-width: 200px;
  max-height: 100px;
  word-wrap: break-word;
}

.gallery-txt {
  font-size: 18px;
  line-height: 24px;
  color: #128391;
  display: flex;
  flex-direction: column;
}

.select_price {
  width: 100%;
}

.container .card_item .gitDetail {
  font-size: 12px;
  line-height: 24px;
  color: black;
  display: flex;
  flex-direction: column;
}

.container .card_item .priceDetail {
  font-size: 14px;
  line-height: 24px;
  color: black;
  display: flex;
  flex-direction: column;
}

.detail-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-box span {
  margin: 2px;
  padding: 2px 10px;
  /* border: 2px solid #c7e6fa; */
  background-color: #9bb4b6;
  border-radius: 50px;
  font-size: 10px;
  font-weight: 600;
  color: #788697;
}

.seeMore {
  margin: 10px auto;
  width: 100px;
  height: 35px;
  background: hsl(245, 44%, 65%);
  color: #fff;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 10px 10px hsl(225, 100%, 94%);
}

@media screen and (max-width: 768px) {
  .container .card_item {
    width: 50%;
  }

  .wrapper .header {
    font-size: 16px;
    height: 50px;
  }
}

@media screen and (max-width: 568px) {
  .container .card_item {
    width: 80%;
    margin: auto;
  }
}

@media screen and (max-width: 320px) {
  .container .card_item {
    width: 100%;
  }
}

.carditem {
  font-size: 12px;
  line-height: 24px;
  margin: 2px;
  color: #128391;
  min-width: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.servicesbtn {
  appearance: none;
  background-color: transparent;
  border-width: 0;

  color: rgb(186, 181, 181);
  outline: 0;
}

.title {
  color: #0b515b;
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  margin-top: 10px;
  font-weight: bold;
  letter-spacing: 7px;
  line-height: 1;
  text-align: center;
}

.title2 {
  color: #0b515b;
  font-family: "Poppins", sans-serif;
  font-size: 70px;
  margin-top: 10px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 40px;
  }

  .title2 {
    font-size: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .title {
    font-size: 50px;
  }

  .title2 {
    font-size: 60px;
  }
}

@media screen and (min-width: 992px) {
  .title {
    font-size: 60px;
  }

  .title2 {
    font-size: 70px;
  }
}

.iconbtn {
  appearance: none;
  background-color: transparent;
  border-width: 0;
  color: rgb(56, 54, 54);
  outline: 0;
}

.navbar {
  --bs-navbar-color: #66707b !important;
  box-shadow: 10px 5px 5px #b5bfc9;
  background-color: #f2f4f6;
  font-size: 14px;
  max-height: 50px;
}

.navbar2 {
  --bs-navbar-color: #66707b !important;
  background-color: #f2f4f6;
  font-size: 14px;
  max-height: 50px;
}

.sidebar {
  width: 20%;
  background-color: white;
  float: left;
  position: sticky;
}

.sidebarWrapper {
  color: #66707b;
  min-width: 200px;
  background-color: #f2f4f6;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 15px;
}

.sidebarListItem {
  padding: 5px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 10px;
  list-style: none;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: rgb(240, 240, 255);
  padding-left: 20px;
  font-weight: bold;
}

.admin-panel-main {
  width: 70%;
  overflow: hidden;
  margin-top: 3.2%;
  margin-left: 20%;
  background-color: #f2f4f6;
  padding: 30px 60px 30px 30px;

  border-radius: 10px;
}
.cart-panel {
  width: 70%;
  overflow: hidden;
  margin-top: 3.2%;
  margin-left: 15%;
  margin-bottom: 3.2%;
  background-color: #f2f4f6;
  padding: 30px 60px 30px 30px;

  border-radius: 10px;
}
.serviceListItem {
  display: flex;
  align-items: center;
  color: #66707b;
}
.serviceList1 {
  margin-top : 50px;
}
.admin-icons {
  color: #66707b;
  margin-right: 20px;
  text-decoration: none !important;
}

.admin-icon {
  margin-right: 10px;
}
.admin-icon :hover {
  color: rgb(81, 96, 194);
}

.back-icon {
  margin-bottom: 4px;
}

.delete {
  border-width: 0px;
  background-color: transparent;
  color: rgb(110, 2, 2);
}

.userprofilephoto {
  width: 22px;
  height: 22px;
  margin-top: 18px;
}

.select {
  margin-top:10px;
}
.auth-button {
  margin: 8px;
  padding: 0;
  border: none;
  background: none;
  color: #66707b;
}

.login_out{
  margin: 8px;
  padding: 0;
  border: none;
  background: none;
  color: #66707b;
}
.login_out:hover{
  color: rgb(81, 96, 194);
}

.SidebarLink {
  text-decoration: none;
  color: #66707b;
}

.SidebarLink:hover {
  text-decoration: none;
  color: rgb(81, 96, 194);
}

.submitbuton {
  background-color: rgb(81, 96, 194);
}

.deletebtn {
  color: #66707b;
  margin-right: 20px;
  background-color: transparent;
  border: none;
}

.updatebtn {
  color: #66707b;
  background-color: transparent;
  border: none;
}

.adminTitle {
  color: #66707b;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  text-align: start;
  margin-left: 20px;
}

.profileTitle {
  color: #66707b;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  text-align: start;
}

.adminTitlepopup {
  color: #555;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  text-align: start;
}
.galleryContent {
  display: none;
}

.iconLink {
  color: #66707b;
}

.sidebaricon {
  margin-right: 5px;
}

.insertbtn {
  font-size: small;
  text-align: end;
  color: #66707b;
}

.insertbtn:hover {
  cursor: pointer;
}

.insertbtn1 {
  font-size: large;
  text-align: center;
  margin-top: 15%;
  color: #66707b;
}

.insertbtn1:hover {
  cursor: pointer;
}

.emptybtn {
  font-size: small;
  text-align: end;
  color: #66707b;
  margin-left: 35px;
}

.adminlist {
  color: #66707b;
}
.cart_form {
  color: #66707b;
  margin-right:5px;
}

.input_cart {
  margin-left: 5px;
}

.list-group {
  margin: -10px;
}

.slick-prev:before,
.slick-next:before {
  color: #fdc500 !important;
}

.plusservice {
  font-size: small;
  color: #9bb4b6;
  margin-top: 15px;
}

.abouticons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.servicename {
  min-height: 60px;
  color: #128391;
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  max-height: 100px;
  word-wrap: break-word;
  margin-top: 5px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.selected {
  background-color: #f5f5f5;
  color: #007bff;
  font-weight: bold;
}

.animated-checkout-button {
  border: solid 1px #166d9a;
  background: #166d9a;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: white;
  font-size: 1.3em;
  text-transform: uppercase;
  padding: 10px 15px 11px 15px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.animated-checkout-button:hover {
  cursor: pointer;
}

.payment-success-container {
  margin-top: 50px;
  text-align: center;
}

.home_page {
  margin-bottom: 5%;
}

.home_p {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}

.navbar-toggler {
  display: none;
}

.footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 60px;
	background-color: #f5f5f5;
	padding: 10px;
	top: auto;
  }
.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.links {
	text-decoration: none;
	color: #66707b;
	margin: 5px;
}

.slider {
  margin-left:7%;
}

.slider2 {
  margin-left:5%;
}

.apt-panel {
  padding: 3%;
}

.adminTitle2 {
  color: #66707b;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  text-align: start;
}

.radio {
  color: #66707b;
  margin-top: 2%;
}