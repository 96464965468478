.navbar1 {
    height: 80px;
    width: 100%;
    color: #66707b !important;
    box-shadow: 10px 5px 5px #b5bfc9;
    background-color: #F2F4F6;
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .logo-nav {
    max-width: 100px;
    margin-top: -19px;
    padding: 0;
    margin-left:10px;
  }

  
.nav-item {
    margin-right: 10px;
    padding: 0;
	color: #66707b;
}
.nav-item1:hover{
  color: rgb(81, 96, 194);
}
.nav-item:hover{
  color: rgb(81, 96, 194);
}

.nav-item1 {
  margin-right: 10px;
  margin-top: 7px;
  padding: 0;
color: #66707b;
}
  .nav-links {
    display: grid;
    grid-template-columns: repeat(5, auto) 1fr;
    list-style: none;
    justify-content: flex-end;
  }

  
  .nav-icon {
    display: none;
    font-size: 2rem;
    cursor: pointer;
  }
  
  @media only screen and (max-width: 500px) {
    .navbar1 {
      position: relative;
    }
  
    .nav-links {
      display: flex;
      flex-direction: column;
      position: relative;
      text-align: center;
      width: 80%;
      left: -100%;
      transition: 0.5s all;
    }
  
    .nav-links.active {
      background-color: #F2F4F6;
      left: 0;
    }
  
    .nav-icon {
      display: flex;
    }
  }
  
  .language-selector {
    cursor: pointer;
    position: relative;
    margin-right: 10px;
    color: #66707b;
  }
  
  .language-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #333;
    color: white;
    list-style: none;
    padding: 0.5rem;
  }
  
  .language-selector:hover .language-dropdown {
    display: block;
  }
  
  .language-option {
    cursor: pointer;
    padding: 0.5rem;
  }
  
  .language-option:hover {
    background-color: #444;
  }